// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as User from "../../../models/User.res.js";

function exec(userId) {
  return Rest.$$fetch("/dashboard/users/" + ID.toString(userId) + "/edit", "Get", {
              NAME: "Json",
              VAL: User.Dashboard.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
