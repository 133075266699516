// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as User from "../../../models/User.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(dailyLeads) {
  return Json_Encode$JsonCombinators.object([[
                "dailyLeads",
                User.DailyLeads.toJson(dailyLeads)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(userId, enabled) {
  return Rest.$$fetch("/dashboard/users/" + (ID.toString(userId) + "/daily_leads"), {
              NAME: "Patch",
              VAL: toJson(enabled)
            }, {
              NAME: "Json",
              VAL: User.Dashboard.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
