// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([
              [
                "first_name",
                req.firstName
              ],
              [
                "last_name",
                req.lastName
              ],
              [
                "dial_code",
                req.dialCode
              ],
              [
                "dial_code_country",
                req.dialCodeCountry
              ],
              [
                "phone",
                req.phone
              ],
              [
                "provider_name_requested",
                req.providerNameRequested
              ],
              [
                "job_title",
                req.jobTitle
              ],
              [
                "description",
                req.description
              ],
              [
                "profile_pic",
                req.profilePic
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(userId, body) {
  return Rest.$$fetch("/dashboard/users/" + ID.toString(userId), {
              NAME: "Patch",
              VAL: toJson(body)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
