// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function messageTypeToClass(messageType) {
  switch (messageType) {
    case "Transparent" :
        return "text-gray-600 bg-transparent";
    case "Error" :
        return "text-red-600 bg-red-100 border-red-400";
    case "Warning" :
        return "text-yellow-600 bg-yellow-100 border-yellow-400";
    case "Info" :
        return "text-blue-600 bg-blue-100 border-blue-400";
    case "Success" :
        return "text-green-600 bg-green-100 border-green-400";
    
  }
}

function ResponseMessage(props) {
  var __className = props.className;
  var __messageType = props.messageType;
  var messageType = __messageType !== undefined ? __messageType : "Transparent";
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: Cx.cx([
                    "p-4 rounded border border-transparent",
                    messageTypeToClass(messageType),
                    className
                  ])
            });
}

function linkTypeToClass(messageType) {
  switch (messageType) {
    case "Transparent" :
        return "font-medium hover:text-teal";
    case "Error" :
        return "font-medium hover:text-blue-400";
    case "Warning" :
        return "font-medium hover:text-orange-600";
    case "Info" :
    case "Success" :
        return "font-medium hover:text-purple-400";
    
  }
}

function ResponseMessage$Link(props) {
  var __className = props.className;
  var __messageType = props.messageType;
  var messageType = __messageType !== undefined ? __messageType : "Transparent";
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("a", {
              children: props.text,
              className: Cx.cx([
                    linkTypeToClass(messageType),
                    className
                  ]),
              href: props.href
            });
}

var Link = {
  linkTypeToClass: linkTypeToClass,
  make: ResponseMessage$Link
};

var make = ResponseMessage;

export {
  messageTypeToClass ,
  make ,
  Link ,
}
/* react/jsx-runtime Not a pure module */
