// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as FetchUsersDashboardRequest from "./FetchUsersDashboardRequest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([
              [
                "role",
                req.role
              ],
              [
                "daily_leads",
                req.dailyLeads
              ],
              [
                "signup_distribution",
                req.signupDistribution
              ],
              [
                "page",
                req.page
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(userId, body) {
  return Rest.$$fetch("/dashboard/users/" + ID.toString(userId), {
              NAME: "Patch",
              VAL: toJson(body)
            }, {
              NAME: "Json",
              VAL: FetchUsersDashboardRequest.Res.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
